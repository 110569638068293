export const Message = (props: any) => {
  const user: string = props.data.user;
  const message: string = props.data.message;
  const timestamp: Date = new Date(props.data.timestamp);

  return (
    <p>
      <span>
        {timestamp.getHours().toString().padStart(2, "0")}:
        {timestamp.getMinutes().toString().padStart(2, "0")}
      </span>
      -<span>{user}</span>: <span>{message}</span>
    </p>
  );
};
