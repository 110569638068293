import React, { useState, useCallback, useEffect, useRef } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Box, Button, Paper, styled, TextField} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { blue, pink, green } from '@mui/material/colors';
import Grid from "@mui/material/Unstable_Grid2";
import { Message } from "./message";

interface SentMessage {
  user: string;
  ip: string;
  message: string;
  timestamp: number;
}

export const TestWebsocket = () => {
  const [socketUrl, setSocketUrl] = useState("wss://back.chat.pabessero.fr");
  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);
  const [message, setMessage] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [ip, setIP] = useState<string>("UNKNOWN");

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const bottomRef = useRef(null);

  //Terms of service Checkbox
  const [isTOSChecked, setIsTOSChecked] = useState(false);

  const checkHandlerTOS = () => {
    setIsTOSChecked(!isTOSChecked);
    console.log({ isTOSChecked });
  };

  //Error message
  const [errorMessage, setErrorMessage] = useState("");

  //Gender
  const [genderValue, setGenderValue] = React.useState('for pab');

  const handleChangeRadioGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGenderValue((event.target as HTMLInputElement).value);
  };

  const controlPropsRadioGender = (item: string) => ({
    checked: genderValue === item,
    onChange: handleChangeRadioGender,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage]);

  useEffect(() => {
    if (ip === "UNKNOWN") {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          setIP(data.ip.toString());
          console.log(ip);
        });
    }
  }, []);

  const handleClickSendMessage = useCallback(() => {
    const sentMessage: SentMessage = {
      user: username,
      ip: ip,
      message: message,
      timestamp: Date.now(),
    };
    if (isTOSChecked === true) {
      if (genderValue !== "" ) {
        if (username !== "") {
          if (message !== "") {
            setErrorMessage("");
            console.log({ genderValue });
            sendMessage(JSON.stringify(sentMessage));
            setMessage("");
          } else {
            setErrorMessage("Error : Your Message is Empty");
            setMessage("");
          }
        } else {
          setErrorMessage("Error : Your Username is Empty");
          setMessage("");
        }
      } else {
        setErrorMessage("Error : Your gender is Empty");
        setMessage("");
      }
    } else {
      setErrorMessage("Error : You hadn't accepted Terms of Service");
      setMessage("");
    }
  }, [ip, username, message, sendMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box sx={{ flexGrow: 1 }} height={"100%"}>
      <Grid container spacing={2} height={"100%"}>
        <Grid xs={10} height={"80%"}>
          <Item elevation={3}>
            <ul>
              {messageHistory.map((message, idx) => (
                <span key={idx}>
                  <Message data={JSON.parse(message.data)} />
                  {/*{message ? message.data : null}*/}
                </span>
              ))}
            </ul>
          </Item>
        </Grid>
        <Grid xs={4} md={6}>
          <Paper>
            <Grid container>
              <Grid position={"sticky"} bottom={"24px"} ref={bottomRef}>
                <TextField
                  id={"username"}
                  value={username}
                  label={"Username"}
                  variant={"filled"}
                  autoComplete={"off"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUsername(event.target.value);
                  }}
                />
              </Grid>
              <Grid>
                <form>
                  <Grid>
                    <div>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        >
                        <FormControlLabel value="female" control={<Radio {...controlPropsRadioGender('female')}
                            sx={{
                            color: pink[800],
                            '&.Mui-checked': {
                            color: pink[600],
                            },
                            }}/>} label="Female"/>
                        <FormControlLabel value="male" control={<Radio {...controlPropsRadioGender('male')}
                            sx={{
                            color: blue[800],
                            '&.Mui-checked': {
                            color: blue[600],
                            }}}/>} label="Male" />
                        <FormControlLabel value="other" control={<Radio {...controlPropsRadioGender('other')}
                            sx={{
                            color: green[800],
                            '&.Mui-checked': {
                            color: green[600],
                            }}}/>} label="Other" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Grid>
                  <hr />
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isTOSChecked}
                      onChange={checkHandlerTOS}
                    />
                    <label htmlFor="checkbox">
                      I agree to Terms of Service{" "}
                    </label>
                    <p>
                      The checkbox is{" "}
                      {isTOSChecked ? "checked" : "unchecked"}
                    </p>
                  </div>
                </form>
              </Grid>
              <TextField
                id={"message"}
                value={message}
                label={"Message"}
                variant={"filled"}
                autoComplete={"off"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMessage(event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClickSendMessage();
                  }
                }}
                fullWidth
                required
              />
            </Grid>
            <div> {errorMessage} </div>
            <Button
              onClick={handleClickSendMessage}
              disabled={readyState !== ReadyState.OPEN}
            >
              Send
            </Button>
          </Paper>
        </Grid>

        {/*<span>The WebSocket is currently {connectionStatus}</span>*/}
        {/*{lastMessage ? <span>Last message: {lastMessage.data}</span> : null}*/}
      </Grid>
    </Box>
  );
};
